<script>
import { Bar } from "vue-chartjs";

export default {
  extends: Bar,
  props: [
    "statistics"
  ],
  data() {
    return {
      options: {
        legend: {
          display: false,
        },
        showTooltips: true,
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            gridLines: {
              display: false,
              drawBorder: false,
            }
          }],
          yAxes: [{
            gridLines: {
              borderDash: [8, 4],
              drawBorder: false,
            }
          }]
        },
      },
      labels: [],
      views: []
    };
  },
  methods: {
    parseStatistics() {
      this.labels = this.statistics.map((statistic) => statistic.date);
      this.views = this.statistics.map((statistic) => statistic.views);
    },
  },
  mounted() {
    this.parseStatistics();
    this.renderChart(
      {
        labels: this.labels,
        datasets: [{
          label: "page visits",
          borderColor: "#249EBF",
          pointBackgroundColor: "white",
          borderWidth: 1,
          pointBorderColor: "#249EBF",
          // backgroundColor: "transparent",
          data: this.views
        }]
      },
      this.options
    );
  },
};
</script>